<template>
  <material-card
    color="blue darken-4"
    :title="$t('charts.client_activity')"
    :text="$moment().format('MMMM YYYY')"
    icon="fal fa-fw fa-buildings"
  >
    <v-data-table
      :headers="computedHeaders"
      :items="Object.values($store.state.charts.clientActivity)"
      :loading="loading"
      sort-by="lab_results_positive"
      :sort-desc="false"
      item-key="uuid"
      :items-per-page="$attrs.rows"
      must-sort
      :custom-sort="sortActivity"
      :footer-props="{
        itemsPerPageText: 'Clients per page:',
      }"
      class="mb-n4"
    >
      <template #header.microbes>
        <v-icon
          small
          title="Average Microbes"
        >
          fal fa-disease
        </v-icon>
      </template>
      <template #item.name="{ item }">
        <client-icon
          :key="item.uuid"
          :name="item.name"
          :icon="item.icon"
        />
      </template>
      <template #item.lab_results_positive="{ item }">
        {{ item.lab_results_positive - item.lab_results_covid_positive }}
        <trend-arrow
          v-if="item.last_month"
          :now="item.lab_results_positive - item.lab_results_covid_positive"
          :then="item.last_month.lab_results_positive - item.last_month.lab_results_covid_positive"
        />
      </template>
      <template #item.lab_results_negative="{ item }">
        {{ item.lab_results_negative - item.lab_results_covid_negative }}
        <trend-arrow
          v-if="item.last_month"
          :now="item.lab_results_negative - item.lab_results_covid_negative"
          :then="item.last_month.lab_results_negative - item.last_month.lab_results_covid_negative"
        />
      </template>
      <template #item.lab_results_covid="{ item }">
        {{ item.lab_results_covid_positive + item.lab_results_covid_negative }}
        <trend-arrow
          v-if="item.last_month"
          :now="item.lab_results_covid_positive + item.lab_results_covid_negative"
          :then="item.last_month.lab_results_covid_positive + item.last_month.lab_results_covid_negative"
        />
      </template>
      <template #item.daily_avg="{ item }">
        {{ item.lab_results_count / $moment().format('D') | numeral('0,0') }}
      </template>
      <template #item.microbes="{ item }">
        {{ item.microbes | numeral('0.00') }}
      </template>
      <template #item.revenue="{ item }">
        {{ item.revenue | currency('$', 0) }}
        <trend-arrow
          v-if="item.last_month"
          :now="item.revenue"
          :then="item.last_month.revenue"
        />
      </template>
      <template #item.reports_count="{ item }">
        {{ item.reports_count }}
      </template>
      <template #footer.prepend>
        <card-reload
          :loading="loading"
          @reload="fetchActivity"
        />
      </template>
    </v-data-table>
  </material-card>
</template>
<script>
export default {
  components: {
    TrendArrow: () => import('@/components/TrendArrow.vue'),
    ClientIcon: () => import('@/components/ClientIcon.vue'),
    CardReload: () => import('@/components/CardReload.vue'),
  },
  props: {
    days: {
      type: Number,
      default: 30,
    },
    hideTrend: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'Client',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Positive',
        value: 'lab_results_positive',
      },
      {
        text: 'Negative',
        value: 'lab_results_negative',
      },
      {
        text: 'Total',
        value: 'lab_results_count',
      },
      {
        text: 'Daily',
        value: 'daily_avg',
        sortable: false,
      },
      {
        text: 'Microbes',
        value: 'microbes',
      },
      {
        text: 'Reports',
        value: 'reports_count',
        align: 'center',
      },
      {
        text: 'Revenue',
        value: 'revenue',
        align: 'right',
      },
    ],
  }),
  computed: {
    computedHeaders () {
      if (this.$auth.check({ financials: 'view' })) {
        return this.headers
      } else {
        return this.headers.filter(x => x.value && x.value != 'revenue')
      }
    },
  },
  created () {
    if (this.$store.state.charts.clientActivity.length === 0) {
      this.fetchActivity()
    }
  },
  methods: {
    fetchActivity ($no_cache = false) {
      this.loading = true
      this.axios.get('charts/activity', {
        params: {
          start_date: this.$moment().startOf('month').format(),
          end_date: this.$moment().endOf('month').format(),
          stats: true,
          no_cache: $no_cache,
        },
        timeout: 0,
      })
        .then((res) => {
          this.$store.state.charts.clientActivity = res.data.clients
        })
        .catch(err => {
          this.$toast.error(err.response?.data?.message || err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sortActivity (items, sortBy, sortDesc) {
      switch (sortBy[0]) {
        case 'lab_results_positive':
            return items.sort((a, b) =>
                sortDesc[0] ?
                ((a.lab_results_positive - a.lab_results_covid_positive) - (b.lab_results_positive - b.lab_results_covid_positive)) :
                ((b.lab_results_positive - b.lab_results_covid_positive) - (a.lab_results_positive - a.lab_results_covid_positive)),
            )
        case 'lab_results_negative':
            return items.sort((a, b) =>
                sortDesc[0] ?
                ((a.lab_results_negative - a.lab_results_covid_negative) - (b.lab_results_negative - b.lab_results_covid_negative)) :
                ((b.lab_results_negative - b.lab_results_covid_negative) - (a.lab_results_negative - a.lab_results_covid_negative)),
            )
        case 'lab_results_covid':
            return items.sort((a, b) =>
                sortDesc[0] ?
                (a.lab_results_covid_positive + a.lab_results_covid_negative) - (b.lab_results_covid_positive + b.lab_results_covid_negative) :
                (b.lab_results_covid_positive + b.lab_results_covid_negative) - (a.lab_results_covid_positive + a.lab_results_covid_negative),
            )
        default:
            return items.sort((a, b) => sortDesc[0] ? a[sortBy[0]] - b[sortBy[0]] : b[sortBy[0]] - a[sortBy[0]])
      }
    },
  },
}
</script>
